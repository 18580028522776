<template>
  <div>
    v1.6.10 (Commit 7ce6469)
  </div>
</template>

<script>
export default {
  name: 'ShowVersion',
}
</script>

